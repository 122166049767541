#watchList{
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
    border: solid #070707;;
}

#watchList ol{
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  list-style: none;
  background-color: rgb(0 0 0 / 50%);
}

/*watchlistaddress*/
.address {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 7.5em;
}

.address > *{
  padding: 0.5em;
}

.address input[type="checkbox"] {
    text-align: center;
    width: 24px;
    min-width: 12px;
    
}

.address .avatar{
  height: 24px;
  width: 24px;
  border-radius: 6px;
}

.address a {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    color: black;
}

.address svg:hover {
    color: red;
    outline: none;
}

.addressOptions {
  display: flex;
  justify-content: space-between;
}

.addressOptions *{
  font-size: 14px;
}
.addressOptions img{
  height: 14px;
  width: 14px;
}

/*WatchList add address*/
.addForm {
  background-color: rgb(0 0 0 / 90%);
  width: 100%;
  display: flex;
  align-items: center;
}

.addForm label {
  position: absolute;
  left: -99999px;
}

.searchForm input[type='search'], .addForm input[type='text'] {
  flex-grow: 1; /*assign all remaining space to the text input based on button*/
  padding: 0.25rem;
  border-radius: 12px;
  margin: 0.5em;
  width: 75%;
}

.addForm button {
  height: 30px;
  min-height: 30px;
  width: 30px;
  min-width: 30px;
  background-color: transparent;
  color: white;
  border: none;
}

.addForm svg:hover {
  color: #00ca00;
  outline: none;
}

.addForm svg{
  width: 16px;
  height: 16px;
}

/**/
.searchForm {
  background-color: rgb(0 0 0 / 90%);
  width: 100%;
  display: flex;
  padding: 0.5em;
}

.searchForm label {
  position: absolute;
  left: -99999px;
}

.searchForm input[type='text'] {
  flex-grow: 1;
  padding: 0.25rem;
  border-radius: 12px;
  margin: 0.5em;
  width: 75%;
}

#watchlistLength{
  width: 100%;
  text-align: center;
  background-color: rgb(0 0 0 / 90%);
}