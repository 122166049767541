#container {
    max-height: 100vh;
    display: flex;
    width: 90%;
    height: 85vh;
    margin: auto;
    background: url("https://64.media.tumblr.com/2ea356fe1419a7e7d10c4ab1eb4ff336/f68df013c46c4b7d-dd/s500x750/7691e4318d1fca7ab2921024bd813825ac0a5cf2.gifv");
    padding: 1em;
    border-radius: 12px;
    box-shadow: 10px 5px 20px rgb(0 0 0);
    border: outset #202020;
}

#handbookNavbar{
    display: flex;
    flex-direction: column;
    width: 250px;
    background-color: rgba(0, 0, 0, 0.9);
    border: ridge #414141;
    border-radius: 12px;
    padding: 1em;
    margin: 1em;
    overflow-y: scroll;
}

.handbookContent{
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center; 
    border-radius: 12px;
    border: ridge #414141;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 1em;
    margin: 1em;
}

.handbookContent *{
    margin: 1em;
}

.handbookContent img{
    border-radius: 12px;
    border: ridge #414141;
}

.featureNav{
    display: flex;
    flex-direction: column;
    margin: 1em 0 1em 0;
    list-style: none;
}

.featureNav li{
    padding: 3%;
    border-radius: 12px;
}

.featureNav li:hover{
    background-color: rgba(75, 75, 75, 0.3);
}

ul{
    list-style: none;
}

.sublist{
    background-color: rgba(21, 21, 21, 0.6);
    border-radius: 12px;
}

.featureList{
    cursor: pointer;
}

.featureList div{
    width: 100%;
    padding: 1em 0 1em 0;
}

#container hr {
    margin: 1em 0 0 0;
    border-color: #414141;
}