#connectBtn{
    background-color: rgb(0 0 0 / 80%);
    border-radius: 12px;
    box-shadow: 10px 5px 20px rgb(0 0 0);
    color: rgb(255, 255, 255);
    width: 150px;
    height: 50px;
    margin-left: auto;
}

#switchChainBtn{
    background-color: rgb(116 117 185 / 80%);
    border-radius: 12px;
    box-shadow: 10px 5px 20px rgb(0 0 0);
    color: rgb(255, 255, 255);
    width: 225px;
    height: 50px;
    margin-left: auto;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    margin-top: 0;
}

.overlay {
    background: rgba(13,13,13,0.8);
}
.modalContent {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(0 0 0 / 90%);
    padding: 2em;
    border-radius: 12px;
    max-width: 600px;
    min-width: 300px;
    text-align: left;
    color: white;
}

#closeModalBtn {
    position: absolute;
    top: 10px;
    right: 10px;
}

#modalAccountInfo, #modalWatchoorInfo{
    background-color: rgb(0 0 0 / 80%);
    padding: 1em;
    border-radius: 12px;
    margin-top: 1em;
}

#modalAccountInfo a{
    color: white;
    text-decoration: none;

}

#modalAccountInfo img{
    height: 24px;
    width: 24px;
    border-radius: 6px;
}