#dosbox{
    height: 100%;
    background-color: rgb(0 0 0 / 50%);
      border: solid #070707;;
}

#gameScreen{
    width: 100%;
    height: 90%;
    background: url('../assets/dosbox.gif') no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

#controls{
    display: flex;
    width: 100%;
}

#controls button{
    background-color: rgb(0 0 0 / 90%);
    color: white;
    width: inherit;
}

/* #controls button:hover{
    background-color: limegreen;
} */

#gameContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 95%;
}

#ejectBtn{
    color: white;
    background-color: black;
}

#ejectBtn:hover{
    background-color: red;
}