#trollbox{
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid #070707;
}

#trollboxHeader{
  background-color: black;
  width: 100%;
  height: 25px;
}

.message{
  display: flex;
  padding: 1em 0 1em 0;
}

#messages{
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
  width: 100%;
}

#chatInfo{
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: rgb(0 0 0 / 80%);
}

#chatForm{
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: rgb(0 0 0 / 90%);
  display: flex;
}

.message p{
  word-break: break-word;
}

.message img{
  width: 18px;
  border-radius: 4px;
}

#chatForm > div{
  width: 90%;
}

#chatForm > div > div{
  background-color: #070707;
}

#chatForm svg{
  fill: white;
  width: 16px;
  height: 16px;
}

#chatForm svg:hover{
  fill: #00ca00;
}

#chatForm button{
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding-left: 14px;
}

#messageInput{
  width: 90%;
}

#chatForm > div > div > div {
  color: white;
  left: 0;
  bottom: 0;
  border: solid #202020;
  background-color: rgb(0 0 0 / 90%);
  border-radius: 24px;
}

#chatForm > div > div> div > div{
  padding: 0.5em;
  background-color: #070707;
}

#chatForm > div > div > div > div > section{
  width: 100% !important;
  background-color: rgb(0 0 0 / 90%);
  border: none;
}