#navMenu {
    grid-area: navbar;
    width: 100px;
    font-size: 10px;
    height: 100%;
    background-color: rgb(0 0 0 / 90%);
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
  }

#logo{
  width: 80%;
  transition: .75s ease;
}

#logoOverlay{
  transition: 2s ease;
  opacity: 0;
  position: absolute;
  display: flex;
  top: 22.5px;
  left: 42.5px;
  flex-direction: column;
}

#logoOverlay svg, #gweiTracker svg, #priceTracker svg{
  width: 16px;
  height: 16px;
}

#logoContainer:hover #logo{
  opacity: 0.3;
}

#logoContainer:hover #logoOverlay{
  opacity: 1;
  filter: drop-shadow(0px 0px 5px white) brightness(1.5);
}

#menu{
  list-style: none;
}

.menuItem{
  padding-top: 10vh;
}

#menu a, #menu a p, #menu p{
  color: white;
  text-decoration: none;
  filter: drop-shadow(0px 0px 20px white);
  transition: .5s ease;
}

#menu a:hover, #menu a:hover p{
  filter: drop-shadow(0px 0px 5px white) brightness(2.5);
}

.menuIcon{
  width: 32px;
  height: 32px;
}

#priceTracker{
  padding-top: 20vh;
}

#priceTracker svg:hover{
  cursor: default;
}

#themeBtn{
  border-radius: 24px;
  background-color: black;
}

#themeBtn span{
  font-size: 2em;
}

.priceTooltip{
  font-size: 10px !important;
  font-family: monospace !important;
  width: 56px !important;
}