.normal, .smallValue, .mediumValue, .largeValue, .massiveValue, .jesusValue, .holyshitValue, .contractCreation{
    min-width: 10em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 56px;
}

.normal{
    min-height: 5em;
}

.smallValue, .mediumValue, .largeValue, .massiveValue, .jesusValue, .holyshitValue{
    color: #7a86fb;
    border: 1px inset #7a86fb;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    min-height: 10em;
}

.smallValue{
    background: url(https://media2.giphy.com/media/3oEdvbpl0X32bXD2Vi/giphy.gif) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
  
.mediumValue{
    background: url(https://media4.giphy.com/media/h0MTqLyvgG0Ss/giphy.gif?cid=ecf05e476otp58306kqs6kfz8jopwh20dvdbdjfw9ylf87rv&rid=giphy.gif&ct=g) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-size: cover;
}

.largeValue{
    background: url(https://media0.giphy.com/media/SsTcO55LJDBsI/giphy.gif?cid=ecf05e47n1tjvwfn9w8xy3nrc5s3bavfz2e5kzcfjg54aqqv&rid=giphy.gif&ct=g) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.jesusValue{
    background: url(https://media1.giphy.com/media/2rqEdFfkMzXmo/giphy.gif?cid=ecf05e47bfxccg88eu7zyrco2fzbfgbkfcs7ly82o5dlkqut&rid=giphy.gif&ct=g) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.massiveValue{
    background: url(https://media4.giphy.com/media/pPzjpxJXa0pna/giphy.gif?cid=ecf05e47cxdka0pq0rlys0vh52fxyvg8r4i2k0bcywahoqgq&rid=giphy.gif&ct=g) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.holyshitValue{
    background: url(https://c.tenor.com/ZMlYoFXVCLgAAAAd/indiana-jones-face-melting.gif) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-size: cover;
}

.contractCreation{
    color: gold;
    background: url(https://media1.giphy.com/media/hYAvfvuj8xKxO/giphy.gif?cid=ecf05e47xqlj115qdk20c6xpl9hgh2sbiijkoenrarafjxth&rid=giphy.gif&ct=g) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border: 1px inset gold;
}

.usdBuy{
    background: url(../assets/dompeet.gif) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: red;
    border: 1px inset red;
    border-radius: 56px;
    min-height: 10em;
}

.usdSell{
    background: url(../assets/pompeet1.gif) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: #13ff00;
    border: 1px inset #13ff00;
    border-radius: 56px;
    min-height: 10em;
}

.ethSell{
    background: url(../assets/vitalikDompeet.gif) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: red;
    border: 1px inset red;
    border-radius: 56px;
    min-height: 10em;
}

.btcSell{
    background: url(../assets/btcSell.webp) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: red;
    border: 1px inset red;
    border-radius: 56px;
    min-height: 10em;
}

.ethBuy{
    background: url(../assets/vitalikPompeet.gif) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: #13ff00;
    border: 1px inset #13ff00;
    border-radius: 56px;
    min-height: 10em;
}

.btcBuy{
    background: url(../assets/btcBuy.gif) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: #13ff00;
    border: 1px inset #13ff00;
    border-radius: 56px;
    min-height: 10em;
}

.usdTransfer{
    background: url(https://media0.giphy.com/media/Y2ZUWLrTy63j9T6qrK/giphy.gif?cid=ecf05e47yr23xlt1nc4qwfzjxtpr3r12oguy9ue75tyf9a0g&rid=giphy.gif&ct=g) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: #0080ff;
    border: 1px inset #0080ff;
    border-radius: 56px;
    min-height: 10em;
}

.ethTransfer{
    background: url(../assets/ethTransfer.gif) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: #7a86fb;
    border: 1px inset #7a86fb;
    border-radius: 56px;
    min-height: 10em;
}

.btcTransfer{
    background: url(../assets/btcTransfer.gif) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: #f7931a;
    border: 1px inset #f7931a;
    border-radius: 56px;
    min-height: 10em;
}