footer {
    grid-area: footer;
    /* margin: 0% 2.5% 0% 2.5%; */
    background-color: rgb(0 0 0 / 90%);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    /* border-radius: 12px; */
    font-size: 12px;
    height: 75px;
}

footer a{
  color: white;
  text-decoration: none;
}
