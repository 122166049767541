#chainNav{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(0 0 0 / 90%);
    z-index: 1;
}

.chainChangeBtn{
    width: 12.5%;
    background-color: black;
    color:white;
    box-shadow: none;
    border-radius: 0px 0px 12px 12px;
    /* outline: outset; */
    outline-width: thin;
}

#chainNav button{
    box-shadow: none;
    /* border: solid #111111; */
    background-color: rgb(0 0 0 / 90%);background-color: rgb(0 0 0 / 90%);
}

#toggledChain{
    border: solid #7475b9;
}

#blockInfo{
    display: flex;
    justify-content: space-around;
    height: 50%;
    align-items: center;
}

#blockInfo a{
    color: white;
    text-decoration: none;
}

#blockInfo a:hover{
    text-decoration: underline;
}

#chainContainer{
    height: 100%;
    width: 1600px;
}

#blockNumber{
    text-shadow:
    0 0 16px #fff, 0 0 0px #fff, 0 0 21px #fff, 0 0 42px #00ff0f, 0 0 82px #21ff00, 0 0 92px #21ff00, 0 0 102px #00ff3e, 0 0 151px #00ca00;
}

#gasUsed{
    text-shadow:
    0 0 16px #fff, 0 0 0px #fff, 0 0 21px #fff, 0 0 42px #ff3c00, 0 0 82px #ff3300, 0 0 92px #ff2600, 0 0 102px #ff3c00, 0 0 151px #ca0000;
}

#feesBurnt{
    text-shadow:
    0 0 16px #fff, 0 0 0px #fff, 0 0 21px #fff, 0 0 42px #ffa600, 0 0 82px #ff8800, 0 0 92px #ffc400, 0 0 102px #ff6600, 0 0 151px #ca7200;
}