main iframe{
  width: 700px;
  height: 400px; 
  border: 10px solid black;
  border-radius: 12px;
  box-shadow: 10px 5px 20px rgb(0 0 0);
}

#header{
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
  width: 100%;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2em;
}

#headerConnectBtn{
  margin-left: auto;
  padding-right: 4em;
}

#headerContent{
  font-size: larger;
}

#headerContent h3{
  width: 1000px;
}

#headerContent *{
  margin-top: 2em;
}

#landingBtns{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
}

#landingBtns button{
  background-color: rgb(0 0 0 / 80%);
  border-radius: 12px;
  box-shadow: 10px 5px 20px rgb(0 0 0);
  color: rgb(255, 255, 255);
  width: 150px;
  height: 50px;
}

#featuresRow{
  display: flex;
  flex-direction: row;
  margin-top: 2em;
  width: 90%;
  justify-content: space-evenly;
}

.feature{
  width: 275px;
  height: 225px;
  background-color: rgb(0 0 0 / 80%);
  text-align: center;
  align-items: center;
  border-radius: 12px;
  box-shadow: 10px 5px 20px rgb(0 0 0);
  padding: 1em;
  border: solid black;
}

.feature:hover{
  background-color: rgb(0 0 0 / 90%);
}

.feature p{
  padding-top: 1em;
}

.featureImg{
  background-color: #535353;
  width: 35%;
  height: 35%;
  border-radius: 9999px;
  margin-top: -3rem;
  text-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.featureImg svg{
  height: 100%;
}

.landingText{
  width: 1300px;
  text-align: center;
  margin-top: 4em;
  margin-bottom: 4em;
  color: black;
}

#metamaskLink{
  color: orange;
  background-color: rgb(0 0 0 / 80%);
  border-radius: 12px;
  box-shadow: 10px 5px 20px rgb(0 0 0);
  width: 200px;
  padding: 1em;
  text-align: center;
  margin-top: 4em;
  margin-bottom: 4em;
  border: solid black;
}