html{
  font-size: 14px;
}

* {
  margin: 0;
  padding: 0;
  transition: .15s ease;
}

body {
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  font-family: monospace;
}

main{
  grid-area: content;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

header {
  grid-area: header;
  display: flex;
  flex-direction: column;
  align-items: center;
}

button, a, svg{
  cursor: pointer;
}

button, select, input{
  font-family: monospace;
}

.App {
  display: grid;
  grid-template-areas:
  'navbar header'
  'navbar content'
  'navbar footer';
  grid-template-columns: 100px auto;
  grid-template-rows: auto auto auto;
  background-color: white;
  height: auto; 
  width: 100%; 
}

#light{
  background: url(./assets/light.gif) no-repeat;
  background-size: cover;
}

#dark{
  background: url(./assets/dark.gif) no-repeat;
  background-size: cover;
}

#light tbody tr:nth-child(even), #light ol li:nth-child(even){
  background-color: rgba(255, 255, 255, 60%);
}
#light tbody tr:nth-child(odd), #light ol li:nth-child(odd){
  background-color: rgba(255, 255, 255, 50%);
}

#dark tbody tr:nth-child(even), #dark ol li:nth-child(even){
  background-color: rgb(0 0 0 / 70%);
}

#dark tbody tr:nth-child(odd), #dark ol li:nth-child(odd){
  background-color: rgb(0 0 0 / 50%);
}

#light tbody tr, #light ol li, #light ol li a{
  color: black;
}

#dark tbody tr , #dark main h3, #dark main h1, #dark ol li, #dark ol li a{
  color: white;
}

#light input, #light select{
  background: white;
  color: black;
}

#dark input, #dark select{
  background: #070707;
  color: white;
  border: solid #202020;;
}

* {
  scrollbar-width: thin;
  scrollbar-color: black #eee;
}

body.activeModal {
  overflow-y: hidden;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #070707;;
}

*::-webkit-scrollbar-thumb {
  background-color: #535353;
  border-radius: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% {  transform: rotate(359deg); }
}
.loadingSvg {
  animation: spin 2s linear infinite;
}

.tooltip{
  font-size: 10px !important;
  font-family: monospace !important;
  border-radius: 24px !important;
}

.react-grid-item > .react-resizable-handle::after {
  border-right: 2px solid rgba(255, 255, 255, 0.5) !important;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5) !important;
}

#mobileDisplay{
  height: 100vh;
  display: flex;
  justify-content: center;
  width: 100vw;
  text-align: center;
}