#feed{
    overflow: hidden;
    height: 100%;
    border: solid #070707;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#feed > div{
    width: 100%;
}

#feed table{
    width: 100%;
    border-collapse: collapse;
}

#headerRow{
    text-align: center;  
}

.feedRow svg:hover {
    color: red;
    outline: none;
}

.feedRow a svg:hover, .feedRow a:hover{
    color: inherit;
    text-decoration: underline;
}

.feedRow a {
    color: inherit;
    text-decoration: none;
}

.feedRow td{
    padding: 0 1em 0 1em;
}

#feed thead {
    background-color: rgb(0 0 0 / 90%);
    position: sticky;
    top: 0;
}

#tableContainer{
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    background-color: rgb(0 0 0 / 50%);
}

.avatar{
    height: 24px;
    width: 24px;
    border-radius: 6px;
}

#pageOptions{
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    bottom: 0;
    background-color: rgb(0 0 0 / 90%);
}

#pageOptions select, #pageOptions input {
    border-radius: 6px;
    border: solid;
    border-color: #161515;
    margin: 0 0.5em 0 0.5em;
}

#pageOptions button{
    background-color: #1e1e1e;
    border-radius: 6px;
    color: white;
    border: solid black;
}

#pageOptions button{
    margin: 0 0.5em 0 0.5em;
}
#tableOptions svg{
    margin: 0 1.5em 0 1.5em;
}

#pageOptions button:disabled, #pageOptions button[disabled]{
    color: #5e5e5e;
}

#feedSearch{
    width: 100%; 
    border-radius: 12px;
    margin: 0.25em;
    padding: 0.25rem;
}

#tableOptions, .deleteCell{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.deleteCopyContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.deleteCopyContainer svg{
    margin: 1em;
}

#tableOptions{
    background-color: rgb(0 0 0 / 90%);
}

#walletWarning p{
    margin-top: 30%; 
    text-align: center;
}

.copyBtn svg:hover{
    color: inherit;
}

.copytoClipBoardTooltip{
    font-size: 10px !important;
    font-family: monospace !important;
    border-radius: 24px !important;
}

.tokenLogo{
    border-radius: 100px;
    height: 18px;
    width: 18px;
    background-color: white;
}

.logs{
    display: flex;
    align-items: center;
}

.logs > span{
    margin-right: 1em;
    margin-left: 1em;
}

.nftLog{
    display: flex;
    align-items: center;
}

.nftImage{
    width: 50px;
    height: 50px;
    border-radius: 12px;
    border: solid #7a86fb;
}