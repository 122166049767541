#windowHeader{
    background-color: rgb(0 0 0 / 90%);
    width: 100%;
    height: 25px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

#windowHeader button {
  display: inherit;
  background-color: #1e1e1e;
  border-radius: 6px;
  margin: 0 0 0 1em;
}

.windowOptions{
  display: flex;
}